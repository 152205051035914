import React, { useContext, useState, useEffect } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { UPDATE_CPO } from 'actions/partnerManagement';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useParams } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckBox from 'components/inputs/CheckBox';
import { FiCopy } from 'react-icons/fi';
import toast from 'react-hot-toast';

const rand = () => {
  return Math.random().toString(36).substr(2);
};

const remoteToken = () => {
  return rand() + rand() + rand() + rand();
};

const Connectivity = ({ setCurrentTab }) => {
  // const history = useHistory();
  const { navigateTo } = useContext(NavContext);
  const { ocpiId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [copied, setCopied] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const roleId = useSelector((state) => state.partnerManagement.partnerInfo.id);
  const ocpiData = useSelector((state) => state.partnerManagement.partnerInfo);

  const EmspConnectSchema = Yup.object().shape({
    countryCode: Yup.string().required(t('connectivityEMSP.countryCode')),
    partyId: isChecked ? Yup.string().required(t('connectivityEMSP.partyId')) : Yup.string(),
    url: isChecked ? Yup.string().required(t('connectivityEMSP.url')) : Yup.string(),
    token: Yup.string().required(t('connectivityEMSP.token')),
  });

  useEffect(() => {
    if (copied) {
      toast.success('Copied!');
    }
  }, [copied]);

  const handleClickCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const initialValues = ocpiId
    ? {
        countryCode: ocpiData.countryCode ? ocpiData.countryCode : '91',
        partyId: ocpiData.partyId,
        url: ocpiData.url,
        token: ocpiData.token ? ocpiData.token : remoteToken(),
        token_b: ocpiData.token_b ? ocpiData.token_b : '',
      }
    : {
        countryCode: '91',
        partyId: '',
        url: '',
        token_b: '',
        token: remoteToken(),
      };

  return (
    <React.Fragment>
      <LayoutContainer>
        <Card className="customerBooking-table">
          <Formik
            initialValues={initialValues}
            validationSchema={EmspConnectSchema}
            onSubmit={(data) =>
              dispatch({
                type: UPDATE_CPO.REQUEST,
                payload: { data, roleId },
                cb: (res) => {
                  if (_.get(res, 'status', '') === UPDATE_STATUS_CODE) {
                    setCurrentTab('Financials');
                  }
                },
              })
            }
          >
            {({ values, errors, touched, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Card>
                  <div>
                    <Row>
                      <Col>
                        <div className="force-upgrade-field-main--block">
                          <div className="force-upgrade-checkbox-input__block">
                            <label htmlFor="force-upgrade" className="force-upgrade--label">
                              {t('addEMSP.perform_handshake')}
                            </label>
                            <CheckBox checked={isChecked} onChange={handleCheckboxChange} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('addEMSP.countryCode')}
                          placeholder={t('addEMSP.enterCode')}
                          value={values.countryCode}
                          onChange={(val) => setFieldValue('countryCode', val.target.value)}
                          error={errors.countryCode && touched.countryCode ? errors.countryCode : null}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          isRequired={isChecked ? true : false}
                          label={t('addEMSP.partyId')}
                          placeholder={t('addEMSP.enterPartyId')}
                          value={values.partyId}
                          onChange={(val) => setFieldValue('partyId', val.target.value)}
                          error={errors.partyId && touched.partyId ? errors.partyId : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired={isChecked ? true : false}
                          label={t('addEMSP.versionURL')}
                          placeholder={t('addEMSP.enterVersionURL')}
                          value={values.url}
                          onChange={(val) => setFieldValue('url', val.target.value)}
                          error={errors.url && touched.url ? errors.url : null}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          isRequired
                          label={t('addEMSP.ourToken')}
                          placeholder={t('addEMSP.enterToken')}
                          value={values.token}
                          onChange={(val) => {
                            setFieldValue('token', val.target.value);
                          }}
                          error={errors.token && touched.token ? errors.token : null}
                        />
                        <div className="copy-clipboard" onClick={(e) => e.stopPropagation()}>
                          <CopyToClipboard onCopy={handleClickCopy} text={btoa(values.token)}>
                            <FiCopy className="copy-icon" />
                          </CopyToClipboard>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          label={t('addEMSP.partyToken')}
                          placeholder={t('addEMSP.enterPartyToken')}
                          value={values.token_b}
                          onChange={(val) => setFieldValue('token_b', val.target.value)}
                        />
                        <div className="copy-clipboard" onClick={(e) => e.stopPropagation()}>
                          <CopyToClipboard onCopy={handleClickCopy} text={btoa(values.token_b)}>
                            <FiCopy className="copy-icon" />
                          </CopyToClipboard>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
                <div>&nbsp;</div>
                <div className="cpo_save_btn">
                  <div>
                    <Button className="cpo_save_btn__1 " onClick={() => navigateTo('/OCPI-Partner')}>
                      {t('button.cancel')}
                    </Button>
                  </div>
                  <div>
                    <Button type="submit" className="cpo_save_btn__1">
                      {t('button.connect')}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </LayoutContainer>
    </React.Fragment>
  );
};

Connectivity.propTypes = {
  setCurrentTab: PropTypes.func,
};

export default Connectivity;
