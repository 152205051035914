import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Sidebar from 'components/sidebar/index';
import { useLocation } from 'react-router-dom';

export const SidebarContext = React.createContext({});

const MainLayout = (props) => {
  const location = useLocation();

  const splitRoute = location.pathname.split('/').slice(0, 2).join('/');
  const sessionRouteCheck = ['/stations', '/editStation', '/charger', '/editCharger'];
  const checkSessionRoute = ['/chargerStatus', '/stations', '/editStation', '/charger', '/editCharger'];

  const isSessionRoute = sessionRouteCheck.includes(splitRoute);
  const isSessionRoute2 = checkSessionRoute.includes(splitRoute);

  useEffect(() => {
    if (!isSessionRoute || location.pathname === '/chargerStatus') {
      sessionStorage.removeItem('activeTab');
    } else if(!isSessionRoute2 || location.pathname === '/stations') {
      sessionStorage.removeItem('tabActive');
    }
  }, [isSessionRoute, isSessionRoute2]);

  return (
    <React.Fragment>
      <div className="layout-container main-layout-wrapper">
        <Sidebar />
        <SidebarContext.Provider value={{ showToggle: props.showToggle }}>
          <div className="content-layout-wrapper__right">{props.children}</div>
        </SidebarContext.Provider>
      </div>
    </React.Fragment>
  );
};

MainLayout.propTypes = {
  showToggle: PropTypes.bool,
  children: PropTypes.node,
};

export default MainLayout;
