import { authApi } from './authApi';
import { chargingStation } from './chargingStation';
import { vehicle } from './vehicle';
import { tenant } from './tenant';
import { charger } from './charger';
import { chargerBooking } from './chargerBooking';
import { invoice } from './invoice';
import { user } from './user';
import { oem } from './oem';
import { amenity } from './amenity';
import { subscription } from './subscription';
import { profile } from './profile';
import { otp } from './otp';
import { address } from './address';
import { notification } from './notification';
import { tariff } from './tariff';
import { vehicleMake } from './vehicleMake';
import { vehicleModel } from './vehicleModel';
import { activityLog } from './activityLog';
import { electricitySupplier } from './electricitySupplier';
import { stationAccessType } from './stationAccessType';
import { oemVendor } from './oemVendor';
import { faq } from './faq';
import { privacy } from './privacy';
import { connectorType } from './connectorType';
import { chargeSpeed } from './chargeSpeed';
import { role } from './role';
import { dashboard } from './dashboard';
import { chargeOcpp } from './chargerOcpp';
import { chargerState } from './chargerState';
import { oemErrorCode } from './oemErrorCode';
import { dataReport } from './dataReport';
import { generateQrCode } from './generateQrCode';
import { permissions } from './permissions';
import { offers } from './offers';
import { liveChagingSession } from './liveChargingSession';
import { userGroup } from './userGroup';
import { sms } from './sms';
import { email } from './email';
import { module } from './module';
import { rfid } from './rfid';
import { rfidGroup } from './rfidGroup';
import { masterSetting } from './masterSetting';
import { rfidRequest } from './rfidRequest';
import { customer } from './customer';
import { currency } from './currency';
import { chargerStatus } from './chargerStatus';
import { reserveSlot } from './reserveSlot';
import { partnerManagement } from './partnerManagement';
import { chargeCoin } from './chargeCoin';
import { tenantSetting } from './tenantSetting';
import { tenantAdminAccess } from './tenantAdminAccess';
import { CapacityUtilisation } from './capacity_api';
import { negativeValue } from './negativeValue';
import { schedulingSetting } from './schedulingSetting';
import { schedule } from './schedule';
import { communicationManager } from './communicationManager';
import { updateSocBooking } from './socapi';
import { smcGateway } from './smcGateway';
import { tagmanagement } from './tagmanagement';
import { ratingReview } from './rating';
import { noLoadReport } from './noLoadReport';
import { updateBulkNegativeValues } from './updateBulkNegativeValues';
import { accountDeleteRequest } from './accountDeleteRequest';
import { ocpiPartnerGroup } from './ocpiPartnerGroup';
import { reportDownloadRequest } from './downloadAdvanceReport';
import { electricityBillReport } from './electricityBillReport';
import { ownershipType } from './ownershipType';
import { alerts } from './alerts';
import { coupon } from './coupon';
import { voucher } from './voucher';
import { chargerLogGraph } from './chargerLogGraph';

export const API = {
  ...electricityBillReport,
  ...ocpiPartnerGroup,
  ...updateSocBooking,
  ...negativeValue,
  ...CapacityUtilisation,
  ...authApi,
  ...chargingStation,
  ...vehicle,
  ...tenant,
  ...charger,
  ...chargerBooking,
  ...invoice,
  ...user,
  ...oem,
  ...amenity,
  ...subscription,
  ...profile,
  ...otp,
  ...address,
  ...notification,
  ...tariff,
  ...vehicleMake,
  ...vehicleModel,
  ...activityLog,
  ...electricitySupplier,
  ...stationAccessType,
  ...oemVendor,
  ...faq,
  ...privacy,
  ...connectorType,
  ...chargeSpeed,
  ...role,
  ...dashboard,
  ...chargeOcpp,
  ...chargerState,
  ...oemErrorCode,
  ...dataReport,
  ...generateQrCode,
  ...permissions,
  ...offers,
  ...liveChagingSession,
  ...userGroup,
  ...sms,
  ...email,
  ...module,
  ...rfid,
  ...rfidGroup,
  ...masterSetting,
  ...rfidRequest,
  ...customer,
  ...currency,
  ...chargerStatus,
  ...reserveSlot,
  ...partnerManagement,
  ...chargeCoin,
  ...tenantSetting,
  ...tenantAdminAccess,
  ...schedulingSetting,
  ...schedule,
  ...communicationManager,
  ...smcGateway,
  ...tagmanagement,
  ...ratingReview,
  ...noLoadReport,
  ...updateBulkNegativeValues,
  ...accountDeleteRequest,
  ...coupon,
  ...voucher,
  ...reportDownloadRequest,
  ...chargerLogGraph,
  ...ownershipType,
  ...alerts,
};
