import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as _ from 'lodash';
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import { BsSearch } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import { Col, Row } from 'react-bootstrap';
import SearchBox from 'components/general/SearchBox';
import ReactPagination from 'components/general/ReactPagination';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { FETCH_LIVE_CHARGING_SESSION } from 'actions/liveChargingSession';
import { SIDEBARMENU_DATA, AUTO_CHARGE, APP } from 'components/common/constant';
import ToggleSwitch from 'components/inputs/ToggleSwitch';
import SplitFlapDisplay from 'components/general/SplitFlapDisplay';

const ChargingSessionFormWrapper = () => {
  const pubnub = new PubNub({
    publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
    subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
    userId: 'myUniqueUserId',
    logVerbosity: false,
    ssl: true,
  });

  return (
    <PubNubProvider client={pubnub}>
      <ChargingSessionForm />
    </PubNubProvider>
  );
};

const ChargingSessionForm = () => {
  const [searchText, setSearchText] = useState('');
  const [tableReload, setTableReload] = useState(false);
  const [isRealTimeActive, setIsRealTimeActive] = useState(false);
  const [localSessions, setLocalSessions] = useState([]);
  const { t } = useTranslation();
  const { navigateTo } = useContext(NavContext);
  const dispatch = useDispatch();
  const pubnub = usePubNub();

  const allSession = useSelector((state) => state.liveChargingSession.liveChargingSessions);
  const page = useSelector((state) => state.liveChargingSession.page);
  const totalData = useSelector((state) => state.liveChargingSession.total);
  const limit = useSelector((state) => state.liveChargingSession.limit);
  const totalPages = useSelector((state) => state.liveChargingSession.totalPages);

  const refreshTable = () => {
    setTableReload(!tableReload);
    getChargingSession({});
  };

  const getChargingSession = useCallback(
    (data) => {
      if (isRealTimeActive) {
        data = { ...data, limit: 9999 };
      }
      if (searchText) {
        const ChargingSessionData = {
          ...data,
          search: searchText,
          limit: isRealTimeActive ? 9999 : limit,
        };
        dispatch({ type: FETCH_LIVE_CHARGING_SESSION.REQUEST, payload: ChargingSessionData });
      } else {
        dispatch({ type: FETCH_LIVE_CHARGING_SESSION.REQUEST, payload: data });
      }
    },
    [searchText, dispatch, isRealTimeActive]
  );

  useEffect(() => {
    getChargingSession();
  }, [getChargingSession]);

  useEffect(() => {
    setLocalSessions(allSession);
  }, [allSession]);

  useEffect(() => {
    if (isRealTimeActive) {
      getChargingSession({ limit: 9999 });

      pubnub.subscribe({ channels: ['live_charging_session'] });

      const listener = {
        message: (messageEvent) => {
          setLocalSessions((prevSessions) => {
            let newSessionData, meterValue;

            if (messageEvent.message.bookingBytransactionId) {
              newSessionData = messageEvent.message.bookingBytransactionId;
              meterValue = messageEvent.message.meterValue;
            } else {
              newSessionData = messageEvent.message;
              meterValue = newSessionData.meterValue;
            }

            if (!newSessionData) {
              return prevSessions;
            }

            // Handle session removal (with delay to play remove animation)
            if (newSessionData.booking_start && newSessionData.booking_stop) {
              const updatedSessions = prevSessions.map((session) => (session.id === newSessionData.id ? { ...session, removing: true } : session));

              // Remove session after animation duration
              setTimeout(() => {
                setLocalSessions((prev) => prev.filter((session) => session.id !== newSessionData.id));
              }, 500); // Animation duration (matches CSS)

              return updatedSessions;
            }

            // Check if the session exists
            const sessionIndex = prevSessions.findIndex((session) => session.id === newSessionData.id);

            if (sessionIndex !== -1) {
              const updatedSessions = [...prevSessions];
              updatedSessions[sessionIndex] = {
                ...updatedSessions[sessionIndex],
                ...newSessionData,
                meterValue: {
                  ...updatedSessions[sessionIndex].meterValue,
                  current_soc: newSessionData.StopSoC ?? updatedSessions[sessionIndex].meterValue.current_soc,
                  ...meterValue,
                },
                addAnimation: false,
              };
              return updatedSessions;
            } else {
              // Add new session with the animation
              return [
                {
                  ...newSessionData,
                  meterValue: {
                    current_soc: newSessionData.StopSoC,
                    ...meterValue,
                  },
                  addAnimation: true,
                },
                ...prevSessions,
              ];
            }
          });
        },
      };

      pubnub.addListener(listener);

      return () => {
        pubnub.unsubscribe({ channels: ['live_charging_session'] });
        pubnub.removeListener(listener);
      };
    }
  }, [pubnub, isRealTimeActive]);

  const handleSearch = () => {
    getChargingSession({});
  };

  const handlePageClick = useCallback(
    (page) => {
      const data = {
        page: page.selected + 1,
      };
      getChargingSession(data);
    },
    [getChargingSession]
  );

  const toggleRealTimeUpdates = () => {
    setIsRealTimeActive(!isRealTimeActive);
  };

  const renderTableRow = (liveChargingSession) => (
    <tr
      key={liveChargingSession.id}
      className={liveChargingSession.addAnimation ? 'add-row-animation' : liveChargingSession.removing ? 'remove-row-animation' : ''}
    >
      <td>
        <SplitFlapDisplay text={_.get(liveChargingSession, 'customer_user_booked.name', '')} />
      </td>
      <td
        onClick={() =>
          navigateTo(`/customerProfile/${_.get(liveChargingSession, 'customer_user_booked.id', '')}`, {
            backTo: 'liveChargingSessions',
          })
        }
        style={{
          cursor: _.get(liveChargingSession, 'customer_user_booked.phone') ? 'pointer' : 'default',
          textDecoration: 'none',
        }}
        onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
        onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
      >
        <SplitFlapDisplay text={_.get(liveChargingSession, 'customer_user_booked.phone', '')} />
      </td>
      <td
        onClick={() => navigateTo(`/charger/${_.get(liveChargingSession, 'charger.id', '')}`, { backTo: 'liveChargingSessions' })}
        style={{ cursor: 'pointer', textDecoration: 'none' }}
        onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
        onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
      >
        <SplitFlapDisplay text={`${_.get(liveChargingSession, 'charger.charger_id', '')}(${_.get(liveChargingSession, 'connectorId', '')})`} />
      </td>
      <td>
        <SplitFlapDisplay text={_.get(liveChargingSession, 'idTag', '')} />
      </td>
      <td>
        <SplitFlapDisplay text={_.get(liveChargingSession, 'StartSoC', '')} />
      </td>
      <td>
        <SplitFlapDisplay text={_.get(liveChargingSession, 'meterValue.current_soc', '')} />
      </td>
      <td>
        <SplitFlapDisplay text={moment.utc(moment().diff(moment(liveChargingSession.booking_start))).format('H:mm:ss')} />
      </td>
      <td>
        <SplitFlapDisplay
          text={
            _.get(liveChargingSession, 'meterValue.voltage') !== null && !isNaN(_.get(liveChargingSession, 'meterValue.voltage'))
              ? parseFloat(_.get(liveChargingSession, 'meterValue.voltage')).toFixed(2)
              : null
          }
        />
      </td>
      <td>
        <SplitFlapDisplay
          text={
            _.get(liveChargingSession, 'meterValue.current') !== null && !isNaN(_.get(liveChargingSession, 'meterValue.current'))
              ? parseFloat(_.get(liveChargingSession, 'meterValue.current')).toFixed(2)
              : null
          }
        />
      </td>
      <td>
        <SplitFlapDisplay
          text={
            _.get(liveChargingSession, 'meterValue.temperature') !== null && !isNaN(_.get(liveChargingSession, 'meterValue.temperature'))
              ? parseFloat(_.get(liveChargingSession, 'meterValue.temperature')).toFixed(2)
              : null
          }
        />
      </td>
      <td>
        <SplitFlapDisplay
          text={
            _.get(liveChargingSession, 'meterValue.energy') !== null && !isNaN(_.get(liveChargingSession, 'meterValue.energy'))
              ? parseFloat(_.get(liveChargingSession, 'meterValue.energy')).toFixed(2)
              : null
          }
        />
      </td>
      <td>
        <SplitFlapDisplay text={_.get(liveChargingSession, 'transaction_id', '')} />
      </td>
      <td>
        <SplitFlapDisplay text={moment(liveChargingSession.booking_start).format('DD/MM/YYYY H:mm:ss')} />
      </td>
      <td>
        <SplitFlapDisplay
          text={
            liveChargingSession.is_rfid_based_booking ? SIDEBARMENU_DATA.RFID : liveChargingSession.is_autocharge_based_booking ? AUTO_CHARGE : APP
          }
        />
      </td>
    </tr>
  );

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={t('header.liveChargingSessions')} />
        <div className="page-content-wrapper scrollable">
          <div className="liveChargingSession-management-page_main">
            <div className="liveChargingSession-list-main-block">
              <Card>
                <div className="liveChargingSession-list-block">
                  <div className="liveChargingSession-search-box">
                    <Row>
                      <Col xl={9} lg={9} md={9}>
                        <SearchBox
                          preIcon={<BsSearch />}
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleSearch();
                            }
                          }}
                        />
                      </Col>
                      <Col xl={1} lg={1} md={1}>
                        <Button className="addchargingstationbtn primary-button btn btn-danger" onClick={refreshTable}>
                          {t('liveChargingForm.refresh')}
                        </Button>
                      </Col>
                      <Col xl={2} lg={2} md={3} className="d-flex align-items-start mt-3">
                        <div className="d-flex gap-3">
                          <ToggleSwitch
                            id="toggle-switch"
                            name="toggle-switch"
                            checked={isRealTimeActive}
                            onChange={(event) => toggleRealTimeUpdates(event)}
                          />
                          {t('liveChargingForm.realTimeUpdate')}
                        </div>
                      </Col>
                    </Row>
                    <div className="liveChargingSession-list-table">
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <span>{t('liveChargingForm.userName')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.phoneNumber')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.connector')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.rfid')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.startSoC')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.currentSoC')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.Duration')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.voltage')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.current')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.temperature')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.energy')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.transactionID')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.startTime')}</span>
                              </th>
                              <th>
                                <span>{t('liveChargingForm.InitiatedFrom')}</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.isEmpty(localSessions) ? (
                              <tr>
                                <td colSpan={14} className="border-0">
                                  <div className="empty-data-block">{t('liveChargingForm.noData')}</div>
                                </td>
                              </tr>
                            ) : (
                              localSessions.map(renderTableRow)
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {!_.isEmpty(localSessions) && (
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={handlePageClick}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                        showDataCount={isRealTimeActive ? false : true}
                      />
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default ChargingSessionFormWrapper;