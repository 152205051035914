import React, { useCallback, useEffect, useState, useContext } from 'react';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { BsDot, BsDownload, BsFillPlugFill } from 'react-icons/bs';
import DropDown from 'components/inputs/DropDown';
import { RiDeleteBin6Line } from 'react-icons/ri';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import { Modal } from 'react-bootstrap';
import Switch from 'components/inputs/Switch';
import { GET_CHARGER, DELETE_CHARGER, CHANGE_CHARGER_STATUS } from 'actions/charger';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import * as _ from 'lodash';
import DeleteModal from 'components/general/DeleteModal';
import ChargerInfo from './ChargerInfo';
import LogInfo from './LogInfo';
import ServerOperationInfo from './ServerOperationInfo';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { FiEdit2 } from 'react-icons/fi';
import { MdClose, MdQrCodeScanner } from 'react-icons/md';
import { GENERATE_QR_CODE } from 'actions/generateQrCode';
import { FiCopy } from 'react-icons/fi';
// import { FiMenu } from 'react-icons/fi';
import { FiMoreVertical } from 'react-icons/fi';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Footer from 'components/general/Footer';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
const ChargerDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const history = useHistory();
  const { navigateTo, isAllowed } = useContext(NavContext);
  const location = useLocation();
  const { chargerId } = useParams();
  // const [checked, setChecked] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [qrCodeImage, setQrCodeImage] = useState('');

  const handleCloseDeleteModel = () => setShowDeleteModal(false);

  const handleCloseQrCodeModal = () => setShowQrCodeModal(false);

  const chargerDetailData = useSelector((state) => state.charger.chargerDetails);
  const isLoader = useSelector((state) => state.charger.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserName = _.get(profileData, 'name', '');

  const getChargerDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGER.REQUEST, payload: id });
  }, []);

  const getQrCode = useCallback(() => {
    dispatch({
      type: GENERATE_QR_CODE.REQUEST,
      payload: chargerId,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            setQrCodeImage(res.data.image);
          }
        }
      },
    });
  }, []);

  const editChargerDetail = useCallback(() => {
    const chargerData = {
      chargerId: chargerDetailData.id,
      is_enabled: chargerDetailData.is_enabled !== true,
    };
    dispatch({ type: CHANGE_CHARGER_STATUS.REQUEST, payload: chargerData });
  }, [chargerDetailData]);

  useEffect(() => {
    getChargerDetail(chargerId);
  }, [chargerId]);

  useEffect(() => {
    if (copied) {
      toast.success('Copied!');
    }
  }, [copied]);

  const DeleteCharger = useCallback((chargerId, stationId) => {
    dispatch({
      type: DELETE_CHARGER.REQUEST,
      payload: { chargerId, stationId },
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          setTimeout(() => {
            navigateTo(`/stations/${stationId}`);
          }, 1000);
        }
      },
    });
  }, []);

  const handleClickCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const currentStatus = _.has(chargerDetailData, 'is_enabled') ? (chargerDetailData.is_enabled ? 'Enabled' : 'Disabled') : 'Enable';
  const chargerStatus = _.get(chargerDetailData, 'charger_status', '');
  let statusClassName = chargerStatus === 'Unavailable' ? 'disabled--text' : 'available--text',
    btnLabel,
    btnClassName;
  if (currentStatus === 'In Use') {
    statusClassName = 'in-use--text';
    btnLabel = 'Stop';
    btnClassName = 'btn--red';
  } else if (currentStatus === 'Disabled') {
    // statusClassName = 'disabled--text';
    btnLabel = 'Re-enable';
    btnClassName = 'btn--blue';
  }

  const handleClick = () => {
    if (btnLabel === 'Re-enable') {
      editChargerDetail();
    }
  };

  const downloadImage = () => {
    const qrBlock = document.getElementById('qr_block');

    if (qrBlock) {
      // Set scale factor for high resolution
      const scaleFactor = 5; // Increase the value to improve quality (e.g., 2, 3, 4)

      // Get the dimensions of the qrBlock
      const qrBlockWidth = qrBlock.offsetWidth;
      const qrBlockHeight = qrBlock.offsetHeight;

      // Create a canvas element and set its dimensions with scale factor for high quality
      const canvas = document.createElement('canvas');
      canvas.width = qrBlockWidth * scaleFactor;
      canvas.height = qrBlockHeight * scaleFactor;

      // Get the context and scale it for high-quality rendering
      const context = canvas.getContext('2d');
      context.scale(scaleFactor, scaleFactor);

      // Set background to white for the canvas (to match original block)
      context.fillStyle = 'White';
      context.fillRect(0, 0, qrBlockWidth, qrBlockHeight);

      // Calculate the center of the canvas
      const centerX = qrBlockWidth / 2;

      // Get the text and image elements in the correct order
      const textElements = qrBlock.querySelectorAll('p');
      const img = qrBlock.querySelector('img');

      // Helper function to handle text wrapping
      const drawWrappedText = (context, text, x, y, maxWidth, lineHeight) => {
        const words = text.split(' ');
        let line = '';
        let yPosition = y;
        words.forEach((word) => {
          const testLine = line + word + ' ';
          const testWidth = context.measureText(testLine).width;
          if (testWidth > maxWidth) {
            context.fillText(line, x, yPosition);
            line = word + ' ';
            yPosition += lineHeight;
          } else {
            line = testLine;
          }
        });

        context.fillText(line, x, yPosition); // Draw the remaining text
      };

      // Ensure there are exactly two text elements
      if (textElements.length === 2 && img) {
        const maxTextWidth = qrBlockWidth; // Set some padding around the text
        // const lineHeight = 0; // Adjust the line height for wrapped text
        // Draw the first text element (Charger Id), centered and wrapped
        context.font = '1.2vw Arial'; // Adjust font size
        context.fillStyle = 'black'; // Set text color
        context.textAlign = 'center';
        context.fontWeight = '900';
        const firstTextY = 60; // Y position for the first text
        drawWrappedText(context, textElements[0].textContent, centerX, firstTextY, maxTextWidth);

        // Draw the image, centered, below the first text
        const imgX = centerX - img.width / 2;
        const imgY = firstTextY + 10; // Adjust this Y position based on the text's height
        context.drawImage(img, imgX, imgY, img.width, img.height);
        // Draw the second text element (Scan, Pay & Charge), centered and wrapped, below the image
        const secondTextY = imgY + img.height + 25; // Adjust Y position based on image height
        drawWrappedText(context, textElements[1].textContent, centerX, secondTextY, maxTextWidth);
      }
      // Convert the canvas to a high-quality image and download it
      canvas.toBlob(
        (blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `${_.get(chargerDetailData, 'charger_id', '') || 'qr_code'}.jpg`;
          link.click();
        },
        'image/jpeg',
        1.0 // Set image quality (1.0 is the highest quality)
      );
    }
  };

  const [currentTab, setCurrentTab] = useState(isAllowed('stationChargersLogs') ? 'chargerLogs' : 'info');
  const setTab = (e, tab) => {
    e.preventDefault();
    setCurrentTab(tab);
  };
  const chargingStationId = _.get(chargerDetailData.charging_station, 'id');
  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader
          title={_.get(chargerDetailData, 'charger_id', '')}
          backTo={location.state ? `/${location.state?.backTo}` : `/stations/${_.get(chargerDetailData, 'charging_station.id')}`}
        />
        <div className="page-content-wrapper scrollable">
          {isLoader ? (
            <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
              <div className="charger-detail-page__main">
                <Card>
                  <div className="charger-profileInfo">
                    <div className="charger-detail__one">
                      <div className="charger-box-main">
                        <div className="charger-box">
                          <div className="charger-icon">
                            <span>
                              <Skeleton className="plug--icon" />
                            </span>
                          </div>

                          <div className="charger-profileInfo-wrapper">
                            <div className="charger--name">
                              <Skeleton height={15} width={100} />
                            </div>

                            <div className="charger-status-action__block charger-status--info">
                              <div className="charger-status--block">
                                <div
                                  className={`charger--status ${statusClassName}`}
                                  style={{
                                    borderRadius: '20px',
                                    padding: '0px 10px',
                                    fontSize: '15px',
                                    background:
                                      chargerDetailData.charger_status === 'Unavailable'
                                        ? '#ff8b00'
                                        : chargerDetailData.charger_status === 'In Use'
                                          ? '#4975f2'
                                          : chargerDetailData.charger_status === 'Coming Soon'
                                            ? 'gray'
                                            : '#0f9b72',
                                    color: 'white',
                                  }}
                                >
                                  {currentStatus === 'Disabled' && (
                                    <span className={currentStatus === 'Disabled' ? 'disabled--text text-size' : 'available--text text-size'}>
                                      <BsDot className="dot--icon" size={20} />
                                      <Skeleton height={15} width={100} />
                                    </span>
                                  )}
                                  <span>
                                    {/* <BsDot className="dot--icon" /> */}
                                    <Skeleton height={15} width={100} />
                                  </span>
                                </div>
                              </div>

                              <div className="charger-action--block">
                                {btnLabel && (
                                  <Button className={`charger-action--btn ${btnClassName}`} onClick={handleClick}>
                                    {btnLabel}
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="charger-detail__two">
                      <div className="charger-meta-info">
                        <div className="copy-clipboard__block" title="Copy WebSocket URL">
                          <CopyToClipboard onCopy={handleClickCopy} text={_.get(chargerDetailData, 'ocpp_url', '')}>
                            <button className="copy-btn">
                              <Skeleton circle="true" height={22} width={22} className="copy--icon" />
                            </button>
                          </CopyToClipboard>
                        </div>
                        <div
                          title="Open Charger QR Code"
                          className="qr-code--btn"
                          onClick={() => {
                            getQrCode();
                            setShowQrCodeModal(true);
                          }}
                        >
                          <Skeleton height={22} width={22} />
                        </div>
                      </div>

                      {isAllowed('addCharger') && (
                        <div className="charger-dropdown_btn">
                          <Skeleton height={22} width={22} circle="true" />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="charger-detail__block-main">
                    <div className="tab-block">
                      <div className="tab-nav-block">
                        {isAllowed('stationChargersInformation') && (
                          <a
                            href="#"
                            className={`tab-nav__item ${currentTab === 'info' ? 'active' : ''}`}
                            onClick={(e) => setTab(e, 'info')}
                            data-content="tab-info"
                          >
                            <Skeleton height={15} width={100} />
                          </a>
                        )}
                        {isAllowed('stationChargersLogs') && (
                          <a
                            href="#"
                            className={`tab-nav__item ${currentTab === 'chargerLogs' ? 'active' : ''}`}
                            onClick={(e) => setTab(e, 'chargerLogs')}
                            data-content="tab-server-logs"
                          >
                            <Skeleton height={15} width={100} />
                          </a>
                        )}
                        {isAllowed('stationChargersServerLogs') && (
                          <a
                            href="#"
                            className={`tab-nav__item ${currentTab === 'serverLogs' ? 'active' : ''}`}
                            onClick={(e) => setTab(e, 'serverLogs')}
                            data-content="tab-server-logs"
                          >
                            <Skeleton height={15} width={100} />
                          </a>
                        )}
                        {/* sequential logs */}
                        {isAllowed('stationChargersSequentialLogs') && (
                          <a
                            href="#"
                            className={`tab-nav__item ${currentTab === 'sequentialLogs' ? 'active' : ''}`}
                            onClick={(e) => setTab(e, 'sequentialLogs')}
                            data-content="tab-server-logs"
                          >
                            <Skeleton height={15} width={100} />
                          </a>
                        )}

                        {isAllowed('stationChargersServerOperations') && (
                          <a
                            href="#"
                            className={`tab-nav__item ${currentTab === 'serverOperations' ? 'active' : ''}`}
                            onClick={(e) => setTab(e, 'serverOperations')}
                            data-content="tab-server-operations"
                          >
                            <Skeleton height={15} width={100} />
                          </a>
                        )}
                      </div>
                      <div className="tab-content-block">
                        <div className="tab-content__item active" id="charger-details-tab">
                          {isAllowed('stationChargersInformation') && currentTab === 'info' && <ChargerInfo />}
                          {isAllowed('stationChargersLogs') && <LogInfo logFrom="charger" activeTab="chargerLogs" />}
                          {isAllowed('stationChargersServerLogs') && <LogInfo logFrom="server" activeTab="chargerLogs" />}
                          {isAllowed('stationChargersSequentialLogs') && (
                            <LogInfo showDropdown={false} activeTab="sequentialLogs" searchBoxWidth={500} />
                          )}
                          {isAllowed('stationChargersServerOperations') && currentTab === 'serverOperations' && <ServerOperationInfo />}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </SkeletonTheme>
          ) : (
            <div className="charger-detail-page__main">
              <Card>
                <div className="charger-profileInfo">
                  <div className="charger-detail__one">
                    <div className="charger-box-main">
                      <div className="charger-box">
                        <div className="charger-icon">
                          <span>
                            <BsFillPlugFill className="plug--icon" />
                          </span>
                        </div>

                        <div className="charger-profileInfo-wrapper">
                          <div className="charger--name">{_.get(chargerDetailData, 'charger_id', '')}</div>

                          <div className="charger-status-action__block charger-status--info">
                            <div className="charger-status--block">
                              <div
                                className={`charger--status ${statusClassName}`}
                                style={{
                                  borderRadius: '20px',
                                  padding: '5px 20px',
                                  fontSize: '0.8rem',
                                  fontWeight: '600',
                                  background:
                                    chargerDetailData.charger_status === 'Unavailable'
                                      ? '#ff8b00'
                                      : chargerDetailData.charger_status === 'In Use'
                                        ? '#4975f2'
                                        : chargerDetailData.charger_status === 'Coming Soon'
                                          ? '#8b91a1'
                                          : chargerDetailData.charger_status === 'PowerLoss'
                                            ? '#BD210B'
                                            : '#0f9b72',
                                  color: 'white',
                                }}
                              >
                                {currentStatus === 'Disabled' && (
                                  <span className={currentStatus === 'Disabled' ? 'disabled--text text-size' : 'available--text text-size'}>
                                    <BsDot className="dot--icon" size={20} />
                                    {t('button.disabled')}
                                  </span>
                                )}
                                <span>
                                  {/* <BsDot className="dot--icon" /> */}
                                  {chargerStatus}
                                </span>
                              </div>
                            </div>

                            <div className="charger-action--block">
                              {btnLabel && (
                                <Button className={`charger-action--btn ${btnClassName}`} onClick={handleClick}>
                                  {btnLabel}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="charger-detail__two">
                    <div className="charger-meta-info">
                      <div className="copy-clipboard__block" title="Copy WebSocket URL">
                        <CopyToClipboard onCopy={handleClickCopy} text={_.get(chargerDetailData, 'ocpp_url', '')}>
                          <button className="copy-btn">
                            <FiCopy className="copy--icon" />
                          </button>
                        </CopyToClipboard>
                      </div>
                      <div
                        title="Open Charger QR Code"
                        className="qr-code--btn"
                        onClick={() => {
                          getQrCode();
                          setShowQrCodeModal(true);
                        }}
                      >
                        <MdQrCodeScanner size={22} />
                      </div>
                    </div>

                    {isAllowed('addCharger') && (
                      <div className="charger-dropdown_btn">
                        <DropDown
                          style={{ background: '$primary-bg-color', borderRadius: 'none' }}
                          popupClass="bottom-left"
                          trigger={
                            <div className="charger-dropdown-icon">
                              <FiMoreVertical size={30} />
                            </div>
                          }
                        >
                          <ul>
                            <li className="item">
                              <div className="charger--switch" onClick={editChargerDetail}>
                                <Switch
                                  label={'Disable Charger'}
                                  // onChange={() => setChecked(!checked)}
                                  checked={chargerDetailData.is_enabled === false}
                                  // onClick={editChargerDetail}
                                />
                              </div>
                            </li>
                            <li className="item">
                              <div className="delete--charger" onClick={() => setShowDeleteModal(true)}>
                                <span>
                                  <RiDeleteBin6Line color={'#E12F17'} />
                                </span>
                                <span className="delete-charger-text"> {t('chargerDetail.deleteCharger')} </span>
                              </div>
                            </li>
                            <li className="item" onClick={() => navigateTo(`/editCharger/${chargerDetailData.id}`)}>
                              <div className="edit--charger">
                                <span>
                                  <FiEdit2 title="Edit" color="skyblue" />
                                </span>
                                <span className="edit-charger-text">{t('chargerDetail.editCharger')}</span>
                              </div>
                            </li>
                          </ul>
                        </DropDown>
                      </div>
                    )}
                  </div>
                </div>

                <div className="charger-detail__block-main">
                  <div className="tab-block">
                    <div className="tab-nav-block">
                      {isAllowed('stationChargersInformation') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'info' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'info')}
                          data-content="tab-info"
                        >
                          {t('chargerDetail.information')}
                        </a>
                      )}
                      {isAllowed('stationChargersLogs') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'chargerLogs' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'chargerLogs')}
                          data-content="tab-server-logs"
                        >
                          {t('chargerDetail.chargerLogs')}
                        </a>
                      )}
                      {isAllowed('stationChargersServerLogs') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'serverLogs' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'serverLogs')}
                          data-content="tab-server-logs"
                        >
                          {t('chargerDetail.serverLogs')}
                        </a>
                      )}
                      {/* sequential logs */}
                      {isAllowed('stationChargersSequentialLogs') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'sequentialLogs' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'sequentialLogs')}
                          data-content="tab-server-logs"
                        >
                          {t('chargerDetail.sequentialLogs')}
                        </a>
                      )}

                      {isAllowed('stationChargersServerOperations') && (
                        <a
                          href="#"
                          className={`tab-nav__item ${currentTab === 'serverOperations' ? 'active' : ''}`}
                          onClick={(e) => setTab(e, 'serverOperations')}
                          data-content="tab-server-operations"
                        >
                          {t('chargerDetail.serverOperations')}
                        </a>
                      )}
                    </div>
                    <div className="tab-content-block">
                      <div className="tab-content__item active" id="charger-details-tab">
                        {isAllowed('stationChargersInformation') && currentTab === 'info' && <ChargerInfo />}
                        {currentTab === 'chargerLogs' && <LogInfo logFrom="charger" activeTab="chargerLogs" />}
                        {currentTab === 'serverLogs' && <LogInfo logFrom="server" activeTab="chargerLogs" />}
                        {currentTab === 'sequentialLogs' && <LogInfo showDropdown={false} activeTab="sequentialLogs" searchBoxWidth={500} />}
                        {isAllowed('stationChargersServerOperations') && currentTab === 'serverOperations' && <ServerOperationInfo />}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="charger" onClose={handleCloseDeleteModel} onRemove={() => DeleteCharger(chargerDetailData.id, chargingStationId)} />
          </Modal>
        )}
        {showQrCodeModal && (
          <Modal show={showQrCodeModal} centered onHide={handleCloseQrCodeModal}>
            <React.Fragment>
              <div className="qr-code-modal--main">
                <Card>
                  <div className="qr-code__block">
                    <div className="qr-code-name">
                      <span> {t('chargerDetail.qrCode')} </span>
                    </div>
                    <div className="qr-code-close-btn">
                      <MdClose size={30} color="#be210b" onClick={handleCloseQrCodeModal} />
                    </div>
                  </div>
                  <div className="qrCode--img">
                    {qrCodeImage ? (
                      <div
                        style={{
                          background: 'white',
                          borderRadius: '15px',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '1rem',
                        }}
                        id="qr_block"
                      >
                        <p style={{ color: 'Black', fontSize: '30px', textAlign: 'center', fontWeight: 700 }}>
                          Charger Id : {_.get(chargerDetailData, 'charger_id', '')}
                        </p>
                        <img src={qrCodeImage} alt="" style={{ width: '350px', height: '350px', borderRadius: '15px' }} />
                        <p style={{ color: 'Black', fontSize: '30px', textAlign: 'center', fontWeight: 700 }}>Scan, Pay & Charge</p>
                      </div>
                    ) : (
                      <div className={'empty-qrCode'}>{t('chargerDetail.noQR')}</div>
                    )}
                  </div>
                  <div className="qrCode--button">
                    {qrCodeImage && (
                      <Button onClick={downloadImage}>
                        <BsDownload title="Download" size={30} />
                      </Button>
                    )}
                  </div>
                </Card>
              </div>
            </React.Fragment>
          </Modal>
        )}
        {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
      </LayoutContainer>
    </React.Fragment>
  );
};
export default ChargerDetail;
