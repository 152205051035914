import React, { useCallback, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import * as _ from 'lodash';
import { Formik, Form } from 'formik';
import ProfilePicInput from 'components/inputs/ProfilePicInput';
import OtpConfirmationModal from './OtpConfirmationModal';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SEND_OTP } from 'actions/otp';
import * as Yup from 'yup';
import { CREATE_USER } from 'actions/user';
import { toast } from 'react-toastify';
import { CREATE_STATUS_CODE, FLEET_OWNER } from 'components/common/constant';
import queryString from 'query-string';
import axios from 'axios';
import { cookie } from 'utils/cookies/cookies';
import { useTranslation } from 'react-i18next';
import { NavContext } from 'components/privateRoute/PrivateRoute';

const DriverForm = () => {
  const dispatch = useDispatch();
  const { navigateTo } = useContext(NavContext);
  const { t } = useTranslation();

  const token = cookie.get('token');
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [phoneData, setPhoneData] = useState({});
  const [isOtpVerify, setIsOtpVerify] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(false);

  const profileData = useSelector((state) => state.profile.userProfile);
  const getCountryCode = _.get(parsePhoneNumber(countryCode), 'countryCallingCode', '');
  const getPhoneNumber = _.get(parsePhoneNumber(countryCode), 'nationalNumber', '');
  const currentUserRole = _.get(profileData, 'role', '');

  const sendOtp = useCallback((data) => {
    dispatch({
      type: SEND_OTP.REQUEST,
      payload: data,
      cb: (res) => {
        if (res && res.status === 201) {
          toast.success('OTP sent to your phone number.');
        }
      },
    });
  }, []);

  const addUser = useCallback((data) => {
    dispatch({
      type: CREATE_USER.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          navigateTo('/drivers');
        }
      },
    });
  }, []);

  const handleImage = (image, savePath, setFieldValue) => {
    const fileName = _.get(image, 'name', '');
    setIsImgLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: process.env.REACT_APP_API_URL + `/util/fileupload?name=${fileName}`,
    })
      .then((res) => {
        if (res.status === 201) {
          const signedRequest = _.get(res, 'data.signedRequest', '');
          handleUploadFile(signedRequest, image, savePath, setFieldValue);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const handleUploadFile = (url, imageData, savePath, setFieldValue) => {
    const fileType = _.get(imageData, 'type', '');

    axios({
      method: 'put',
      headers: {
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
      url: url,
      data: imageData,
    })
      .then((res) => {
        if (res.status === 200) {
          setIsImgLoading(false);
          const imgUrlData = queryString.parseUrl(url);
          setFieldValue(savePath, imgUrlData.url);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => console.error('Error -> ', e));
  };

  const UserFormSchema = Yup.object({
    phone: Yup.string().required(t('userForm.phone')),
    name: Yup.string()
      .required(t('userForm.name'))
      .strict(true)
      .trim(t('userForm.space'))
      .matches(/^[A-Za-z\s]+$/, t('userForm.matches'))
      .max(100, t('userForm.maxlimit')),
    // email: Yup.string()
    //   .required(t('userForm.email'))
    //   .strict(true)
    //   .trim(t('userForm.space'))
    //   .email(t('userForm.emailChk'))
    //   .max(100, t('userForm.maxlimit')),
    role: Yup.string().required(t('userForm.role')),
  });

  return (
    <LayoutContainer>
      <AdminHeader title={t('header.addDriver')} backTo="/drivers" />
      <div className="page-content-wrapper scrollable">
        <div className="profile-form-page__main">
          <Card>
            <div className="profile-form-page--inner">
              <Formik
                initialValues={{
                  name: '',
                  phone: '',
                  email: '',
                  role: currentUserRole === FLEET_OWNER ? 'fleet_member' : 'fleet_owner',
                  // access: '',
                  country_code: '91',
                  password: currentUserRole === FLEET_OWNER ? 'As123456' : '',
                  picture: '',
                  address: '',
                  gender: '',
                  dob: '',
                }}
                validationSchema={UserFormSchema}
                onSubmit={(values, { setSubmitting }) => {
                  if (!isValidPhoneNumber(values.phone)) {
                    setPhoneError('Phone Number is invalid');
                  } else {
                    if (isOtpVerify) {
                      // send otp api calling...
                      const phoneNumberDetails = {
                        country_code: getCountryCode,
                        number: getPhoneNumber,
                      };
                      sendOtp(phoneNumberDetails);
                      setPhoneData(phoneNumberDetails);
                      setShowOtpModal(true);
                    } else {
                      //add user api
                      addUser({ ...values, country_code: getCountryCode, phone: getPhoneNumber });
                    }
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, handleChange, setFieldValue, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col xxl={2} xl={3} lg={5} md={4}>
                        <div className="d-flex justify-content-center">
                          <ProfilePicInput
                            showAdd
                            name="picture"
                            path={values.picture}
                            showLoader={isImgLoading}
                            onChange={(e) => handleImage(e.currentTarget.files[0], 'picture', setFieldValue)}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="profile-form__block">
                          <Row>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label={t('userForm.userName')}
                                placeholder={t('userForm.userName')}
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                error={errors.name && touched.name ? errors.name : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <TextInput
                                isRequired
                                label={t('userForm.emailId')}
                                placeholder={t('userForm.emailId')}
                                name="email"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                // error={errors.email && touched.email ? errors.email : null}
                              />
                            </Col>
                            <Col xl={6} lg={12}>
                              <PhoneNumberInput
                                isRequired
                                label={t('userForm.phoneNo')}
                                name="phone"
                                value={values.phone}
                                onChange={(val) => {
                                  setFieldValue(`phone`, val);
                                  setCountryCode(val || '');
                                  if (!_.isEmpty(phoneError)) {
                                    setPhoneError('');
                                  }
                                }}
                                error={errors.phone && touched.phone ? errors.phone : phoneError ? phoneError : null}
                              />
                            </Col>

                            <Col md={12}>
                              <span className="save-btn--block">
                                <Button type="submit" className="profile-save-btn">
                                  {isOtpVerify ? t('userForm.sendOTP') : t('userForm.save')}
                                </Button>
                              </span>
                              <span className="cancel-btn--block">
                                <Button className="cancel-btn" onClick={() => navigateTo(`/drivers`)}>
                                  {t('button.cancel')}
                                </Button>
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
            {showOtpModal && (
              <Modal show={showOtpModal} centered size="lg">
                <OtpConfirmationModal onClose={() => setShowOtpModal(false)} isVerifyOtp={(val) => setIsOtpVerify(val)} phoneData={phoneData} />
              </Modal>
            )}
          </Card>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default DriverForm;
