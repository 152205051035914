import React, { useState } from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import PropTypes from 'prop-types';
import { FloatingLabel } from 'react-bootstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const DateTimePicker = (props) => {
  const {
    placeholderOfStartDate,
    initialValueOfStartDate,
    onChangeOfStartDate,
    classNameOfStartDate,
    placeholderOfEndDate,
    initialValueOfEndDate,
    onChangeOfEndDate,
    classNameOfEndDate,
    floatingLabelOfStartDate,
    floatingLabelClassNameOfStartDate,
    floatingLabelOfEndDate,
    floatingLabelClassNameOfEndDate,
    dateFormatOfStartDate,
    dateFormatOfEndDate,
    timeFormatOfStartDate,
    timeFormatOfEndDate,
    valueOfStartDate,
    valueOfEndDate,
  } = props;

  // disable future dates
  const today = moment();
  const { t } = useTranslation();
  const [selectedStartDate, setSelectedStartDate] = useState(initialValueOfStartDate);

  const disableFutureDate = (current) => {
    return current.isBefore(today);
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    if (onChangeOfStartDate) {
      onChangeOfStartDate(date);
    }
  };

  const disablePastDt = (current) => {
    const selectedTime = moment(selectedStartDate).format('DD/MM/YYYY');
    const currentTime = moment(current).format('DD/MM/YYYY');
    if (currentTime === selectedTime || current.isBetween(selectedStartDate, today)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="dataTimePicker-floating-label-input-group">
      <div className="input-field">
        <FloatingLabel
          label={`${floatingLabelOfStartDate ? floatingLabelOfStartDate : t('dateTimePicker.from')}`}
          className={`dateTimepicker-floating-label ${floatingLabelClassNameOfStartDate ? floatingLabelClassNameOfStartDate : ''}`}
        >
          <Datetime
            value={valueOfStartDate || ''}
            initialValue={initialValueOfStartDate || ''}
            dateFormat={dateFormatOfStartDate || 'DD/MM/YYYY'}
            timeFormat={timeFormatOfStartDate || 'hh:mm:ss A'}
            placeholder={placeholderOfStartDate || 'From...'}
            onChange={handleStartDateChange || function () {}}
            isValidDate={disableFutureDate}
            closeOnSelect = {true}
            className={`${classNameOfStartDate ? classNameOfStartDate : ''}`}
            {...props}
          />
        </FloatingLabel>
      </div>
      <div className="input-field">
        <FloatingLabel
          label={`${floatingLabelOfEndDate ? floatingLabelOfEndDate : t('dateTimePicker.to')}`}
          className={`dateTimepicker-floating-label ${floatingLabelClassNameOfEndDate ? floatingLabelClassNameOfEndDate : ''}`}
        >
          <Datetime
            value={valueOfEndDate || ''}
            initialValue={initialValueOfEndDate || ''}
            dateFormat={dateFormatOfEndDate || 'DD/MM/YYYY'}
            timeFormat={timeFormatOfEndDate || 'hh:mm:ss A'}
            placeholder={placeholderOfEndDate || 'To...'}
            onChange={onChangeOfEndDate || function () {}}
            closeOnSelect = {true}
            className={`${classNameOfEndDate ? classNameOfEndDate : ''}`}
            isValidDate={disablePastDt}
            {...props}
          />
        </FloatingLabel>
      </div>
    </div>
  );
};

DateTimePicker.propTypes = {
  placeholderOfStartDate: PropTypes.string,
  initialValueOfStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
  onChangeOfStartDate: PropTypes.func,
  dateFormatOfStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dateFormatOfEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  timeFormatOfStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  timeFormatOfEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  classNameOfStartDate: PropTypes.string,
  placeholderOfEndDate: PropTypes.string,
  initialValueOfEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
  onChangeOfEndDate: PropTypes.func,
  classNameOfEndDate: PropTypes.string,
  floatingLabelOfStartDate: PropTypes.string,
  floatingLabelOfEndDate: PropTypes.string,
  floatingLabelClassNameOfStartDate: PropTypes.string,
  floatingLabelClassNameOfEndDate: PropTypes.string,
  valueOfStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
  valueOfEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)]),
  valueIsValidDate: PropTypes.bool,
};

export default DateTimePicker;
