import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import { MdClose } from 'react-icons/md';
import { Row, Col } from 'react-bootstrap';
// import { Formik, Form, FieldArray, getIn } from 'formik';
import { Formik, Form } from 'formik';
import TextInput from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_TARIFF, UPDATE_TARIFF } from 'actions/tariff';
import _ from 'lodash';
import * as Yup from 'yup';
import { FETCH_OEM } from 'actions/oem';
import { FETCH_CURRENCY } from 'actions/currency';
import Select from 'components/inputs/Select';
// import SessionHeading from 'components/general/SessionHeading';
// import { BiPlus, BiTrash } from 'react-icons/bi';
import { CREATE_STATUS_CODE, GET_TARRIFS, MANAGE_TARRIFS, UPDATE_STATUS_CODE } from 'components/common/constant';
import { FETCH_USER_GROUP } from 'actions/userGroup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const PricingForm = (props) => {
  const { onClose = _.noop(), isEdit, onSuccess = _.noop() } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allOems = useSelector((state) => state.oem.oems);
  //const allCurrency = useSelector((state) => state.currency.currencies);
  //const allUserGroup = useSelector((state) => state.userGroup.userGroups);
  //const userGroupPage = useSelector((state) => state.userGroup.page);
  const chargeSpotPage = useSelector((state) => state.oem.page);
  //const userGroupTotalPage = useSelector((state) => state.userGroup.totalPages);
  const chargeSpotTotalPage = useSelector((state) => state.oem.totalPages);
  const tariffDetail = useSelector((state) => state.tariff.tariffDetail);
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const addTariff = useCallback((data) => {
    let body = {
      ...data,
      schedule: [
        {
          day: 'daily',
          from: '00:00',
          to: '23:59',
          price_per_unit: data.default_price_per_unit,
        },
      ],
      timing_type: 'daily',
    };
    dispatch({
      type: CREATE_TARIFF.REQUEST,
      payload: body,
      cb: (res) => {
        if (_.get(res, 'status') === CREATE_STATUS_CODE) {
          onSuccess();
          onClose();
        }
      },
    });
  }, []);

  const updateTariff = useCallback((data) => {
    dispatch({
      type: UPDATE_TARIFF.REQUEST,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          onClose();
        }
      },
    });
  }, []);

  const getAllUserGroup = useCallback((data = {}) => {
    dispatch({ type: FETCH_USER_GROUP.REQUEST, payload: data });
  }, []);

  const getAllOem = useCallback((data = {}) => {
    dispatch({ type: FETCH_OEM.REQUEST, payload: data });
  });

  const getAllCurrency = useCallback((data = {}) => {
    dispatch({ type: FETCH_CURRENCY.REQUEST, payload: data });
  });

  useEffect(() => {
    getAllOem();
    getAllUserGroup();
    getAllCurrency();
  }, []);

  const getTariffPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_TARRIFS),
      (data) => data.name
    );

  const manageTariffPermissions =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_TARRIFS),
      (data) => data.name
    );

  const initialValues =
    isEdit && !_.isEmpty(tariffDetail) && !_.isEmpty(tariffDetail.oem)
      ? { ...tariffDetail, oem: _.get(tariffDetail, 'oem.id', '') }
      : isEdit && tariffDetail.oem === null
        ? { ...tariffDetail, oem: 'All' }
        : isEdit && tariffDetail.oem === undefined
          ? { ...tariffDetail, oem: 'All' }
          : {
              name: '',
              user_group: '',
              default_price_per_unit: '',
              default_tax: '',
              // currency: '',
              businessType: '',
              oem: '',
              // load: '',
            };

  const chargeSpotList = [
    { label: 'All', value: 'All' },
    ..._.map(allOems, (oemItem) => {
      return { label: oemItem.name, value: oemItem.id };
    }),
  ];

  const TariffSchema = Yup.object().shape({
    name: Yup.string().required(t('pricingForm.name')),
    oem: Yup.string(),
    load: Yup.string(),
    businessType: Yup.string(),
    currency: Yup.string(),
    default_price_per_unit: Yup.number().positive(t('pricingForm.defaultPricePerUnit')).integer(t('pricingForm.defaultPricePerUnit')),
    default_tax: Yup.number().positive(t('pricingForm.defaultTax')).integer(t('pricingForm.defaultTax')),
  });

  // const currencyList = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allCurrency, (currencyItem) => {
  //     return { label: currencyItem.name, value: currencyItem.id };
  //   }),
  // ];

  return (
    <React.Fragment>
      <div className="pricing-form-page__main">
        <Card>
          <div className="pricing-form-header__block">
            <div className="pricing-header-name">
              <span>{`${isEdit ? 'Edit' : 'Add New'} Tariff`}</span>
              {/* <span>{`Add New Tariff`}</span> */}
            </div>
            <div className="close-btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <div className="pricing-form-body__block">
            <div className="pricing-form--block">
              <Formik
                enableReinitialize={!!isEdit}
                initialValues={initialValues}
                validationSchema={TariffSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const addTariffData = values;
                  if (isEdit) {
                    const newTariff = {
                      ...values,
                      schedule: values.schedule.map((schedule) => {
                        delete schedule._id;
                        delete schedule.id;
                        return {
                          ...schedule,
                          user_group_condition: schedule?.user_group_condition
                            ? schedule.user_group_condition.map((group) => {
                                delete group._id;
                                delete group.id;
                                return {
                                  ...group,
                                };
                              })
                            : [{ price_per_user_group: '', user_group: '' }],
                        };
                      }),
                    };
                    const editTariffData = _.omit(newTariff, ['tenant', 'is_external_tariff', 'external_tariff_id', 'ocpiCredential', 'currency']);
                    if (values.oem === 'All') {
                      const oemData = { ...editTariffData, oem: '' };
                      updateTariff(oemData);
                    } else {
                      updateTariff(editTariffData);
                    }
                  } else {
                    if (values.oem === 'All') {
                      const oemData = { ...addTariffData, oem: '' };
                      addTariff(oemData);
                    } else {
                      addTariff(addTariffData);
                    }
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, handleChange, errors, touched, setFieldValue, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6}>
                        <TextInput
                          label={t('pricingForm.nameLabel')}
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          error={errors.name && touched.name ? errors.name : null}
                        />
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col>
                            <TextInput
                              type="number"
                              label={t('pricingForm.defaultRate')}
                              name="default_price_per_unit"
                              value={values.default_price_per_unit}
                              onChange={(e) => {
                                // Parse the input value as a number
                                const inputValue = parseFloat(e.target.value);

                                // Check if it's a valid number and not negative
                                if (!isNaN(inputValue) && inputValue >= 0) {
                                  // If valid, update the state with the new value
                                  handleChange(e);
                                } else {
                                  // If not valid, you can choose to display an error message or prevent the update
                                  // For example, you can display an error message:
                                  toast.error('Please enter a non-negative number');

                                  // Or prevent the update:
                                  // e.preventDefault();
                                }
                              }}
                            />
                          </Col>
                          {/* <Col lg={6}>
                            <Select
                              label="Currency"
                              placeholder="Select Currency"
                              // options={currencyList}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={getAllCurrency}
                              name="currency"
                              value={values.currency}
                              onChange={(val) => setFieldValue(`currency`, val)}
                            />
                          </Col> */}
                        </Row>
                      </Col>
                      <Col lg={6}>
                        <Select
                          label={t('pricingForm.businessType')}
                          options={[
                            { label: 'Public', value: 'Public' },
                            { label: 'Private', value: 'Private' },
                            { label: 'Hybrid', value: 'Hybrid' },
                          ]}
                          placeholder={t('placeHolder.selectType')}
                          name="businessType"
                          value={values.businessType}
                          onChange={(val) => setFieldValue(`businessType`, val)}
                        />
                      </Col>
                      <Col lg={6}>
                        <Select
                          label={t('pricingForm.chargespotModel')}
                          placeholder={t('placeHolder.selectChargeSpotModel')}
                          // options={_.map(allOems, (oemItem) => {
                          //   return { label: oemItem.name, value: oemItem.id };
                          // })}
                          options={chargeSpotList}
                          onMenuScrollDown={true}
                          getDataOnScrollDown={getAllOem}
                          page={chargeSpotPage}
                          totalPage={chargeSpotTotalPage}
                          name="oem"
                          value={values.oem}
                          onChange={(val) => setFieldValue(`oem`, val)}
                        />
                      </Col>
                      <Col lg={6}>
                        <Row>
                          <Col>
                            <TextInput
                              type="number"
                              label={t('pricingForm.defaultTaxLebel')}
                              name="default_tax"
                              value={values.default_tax}
                              onChange={(e) => {
                                const inputValue = parseFloat(e.target.value);
                                if (!isNaN(inputValue) && inputValue >= 0) {
                                  handleChange(e);
                                } else {
                                  toast.error('Please enter a non-negative number');
                                }
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col lg={6}>
                        <TextInput
                          type="number"
                          post_text="kWh"
                          label="Load/Type"
                          name="load"
                          value={values.load}
                          onChange={(e) => setFieldValue('load', e.target.value)}
                        />
                      </Col> */}
                    </Row>
                    <div className="form-btn--block">
                      <Button className="pricing-form-btn cancel-btn" onClick={onClose}>
                        {t('button.cancel')}
                      </Button>
                      {!_.isEmpty(manageTariffPermissions) || (!_.isEmpty(getTariffPermissions) && !_.isEmpty(manageTariffPermissions)) ? (
                        <Button type="submit" disabled={isSubmitting} className="pricing-form-btn create-btn">
                          {isEdit ? 'Update' : 'Submit'}
                          {/* Submit */}
                        </Button>
                      ) : (
                        <Button className="pricing-form-btn create-btn">{t('button.submit')}</Button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};
PricingForm.propTypes = {
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
  onSuccess: PropTypes.func,
};
export default PricingForm;
